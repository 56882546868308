<template>
  <footer class="footer">
    <div class="container">
      <div class="row justify-content-between">
        <div class="shrink">
          <div class="logo"><img src="@/assets/images/logo-footer.svg" alt=""></div>
          <div class="shrink">
            <div class="copy-text">© Copyright 2022 Credit Score</div>
            <cs-versions />
          </div>
        </div>
        <div class="col-2">
          <div class="title-col">For Users</div>
          <cs-menu-link></cs-menu-link>
        </div>
        <div class="shrink">
          <div class="title-col">Contacts</div>
          <div class="contact-item">
            <div class="name">Phone:</div>
            <div class="value">111-222-3333</div>
          </div>
          <div class="contact-item">
            <div class="name">Support:</div>
            <div class="value"><a href="">info@creditscore.com</a></div>
          </div>
        </div>
        <div class="shrink">
          <div class="title-col">Contacts</div>
          <ul class="social-list">
            <li><a href="">
              <icon-social social="facebook"/>
            </a></li>
            <li><a href="">
              <icon-social social="instagram"/>
            </a></li>
            <li><a href="">
              <icon-social social="twitter"/>
            </a></li>
            <li><a href="">
              <icon-social social="youtube"/>
            </a></li>
          </ul>
        </div>
        <div class="col-3">
          <div class="title-col">Newsletter</div>
          <div class="subscribe-form">
            <input type="text" placeholder="Your Email">
            <button type="submit">
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.93742 12.9852C9.5469 13.3758 9.5469 14.0089 9.93742 14.3995C10.3279 14.79 10.9611 14.79 11.3516 14.3995L9.93742 12.9852ZM16.1978 9.5533C16.5883 9.16277 16.5883 8.52961 16.1978 8.13908C15.8073 7.74856 15.1741 7.74856 14.7836 8.13908L16.1978 9.5533ZM11.3516 14.3995L16.1978 9.5533L14.7836 8.13908L9.93742 12.9852L11.3516 14.3995Z" fill="white"/>
                <path d="M14.0671 20.7675L13.1726 21.2148L14.0671 20.7675ZM15.9173 20.6144L14.9615 20.3203L15.9173 20.6144ZM10.2697 13.6201L9.82253 14.5145L10.2697 13.6201ZM10.717 14.0673L11.6114 13.6201L10.717 14.0673ZM3.72264 8.41978L4.01672 9.37556L3.72264 8.41978ZM3.56951 10.27L3.1223 11.1644H3.1223L3.56951 10.27ZM19.5318 3.55543L19.8258 4.51121L19.5318 3.55543ZM20.7816 4.8053L21.7374 5.09938L20.7816 4.8053ZM19.2377 2.59965L3.42855 7.464L4.01672 9.37556L19.8258 4.51121L19.2377 2.59965ZM3.1223 11.1644L9.82253 14.5145L10.717 12.7257L4.01672 9.37555L3.1223 11.1644ZM9.82253 14.5145L13.1726 21.2148L14.9615 20.3203L11.6114 13.6201L9.82253 14.5145ZM16.8731 20.9085L21.7374 5.09938L19.8258 4.51121L14.9615 20.3203L16.8731 20.9085ZM13.1726 21.2148C13.9796 22.8286 16.3424 22.633 16.8731 20.9085L14.9615 20.3203L13.1726 21.2148ZM9.82253 14.5145V14.5145L11.6114 13.6201C11.4179 13.233 11.104 12.9192 10.717 12.7257L9.82253 14.5145ZM3.42855 7.464C1.70402 7.99462 1.50847 10.3575 3.1223 11.1644L4.01672 9.37555L4.01672 9.37556L3.42855 7.464ZM19.8258 4.51121L19.8258 4.51121L21.7374 5.09938C22.2096 3.56483 20.7722 2.12748 19.2377 2.59965L19.8258 4.51121Z" fill="white"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import CsMenuLink from "@/components/CsMenuLink";
import IconSocial from "@/components/elements/icons/IconSocial";
import CsVersions from "@/components/elements/CsVersions.vue";
export default {
  name: "CsFooter",
  components: {
    CsVersions,
    IconSocial,
    CsMenuLink
  }
}
</script>

<style scoped>

</style>
