<template>
  <template v-if="statusPreload">
    <preloader-progress :width="preloadWidth" :height="preloadHeight"/>
  </template>
  <template v-else>
    <slot/>
  </template>
</template>

<script>
import PreloaderProgress from "@/components/elements/PreloaderProgress";
import showElements from "@/views/exports/show-elements";
import preloadMixin from "@/mixins/preload-mixin";
export default {
  name: "IsPreload",
  mixins: [showElements, preloadMixin],
  components: {PreloaderProgress},
  props: {
    preloadType: {
      type: String,
      required: true,
    },
    preloadWidth: {
      type: Number,
      default: undefined,
    },
    preloadHeight: {
      type: Number,
      default: undefined,
    }
  },
  computed: {
    statusPreload() {
      return this.getStatusPreload(this.preloadType);
    },
  }
}
</script>

<style scoped>

</style>
