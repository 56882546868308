import apiAxios from "@/api-axios";
import router from "@/router";

export default {
  state: {
    user: {},
    profile: {},
    userProfileCache: {},
    appAPIVersion: '0.0.0',
    appAMAVersion: '0.0.0',
  },
  mutations: {
    setAppAPIVersion(state, ver) {
      state.appAPIVersion = ver;
    },
    setAppAMAVersion(state, ver) {
      state.appAMAVersion = ver;
    },
    setUser(state, user) {
      state.user = user;
    },
    setProfile(state, profile) {
      if (profile.postal_address === profile.address) {
        profile.same_address = true;
      }
      if (!profile.address_residence) {
        profile.address_residence = {
          state: "",
          city: "",
          street: "",
          apartment: "",
          zip_first: null,
          zip_second: null
        };
      }
      if (!profile.address_postal) {
        profile.address_postal = {
          state: "",
          city: "",
          street: "",
          apartment: "",
          zip_first: null,
          zip_second: null
        };
      }
      if (profile.address_postal === profile.address_residence) {
        profile.same_address = true;
      }
      state.profile = profile;
    },
    setUserProfileCache(state, [uId, profile]) {
      state.userProfileCache[uId] = profile;
    },
    clearUserProfileCache(state) {
      state.userProfileCache = {};
    },
    clearUser(state) {
      state.user = {};
      state.profile = {};
    }
  },
  getters: {
    user: s => s.user,
    profile: s => s.profile,
    userProfileCache: s=>s.userProfileCache,
    appAPIVersion: s=>s.appAPIVersion,
    appAMAVersion: s=>s.appAMAVersion,
  },
  actions: {

    async apiRegisterUser({dispatch}, user) {
      try {
        const response = await apiAxios.post('/users/', user);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiConfirmRegister({dispatch, commit}, user) {
      try {
        const response = await apiAxios.post('/users/confirm_register/', user);
        await commit('setAccessToken', `${response.data.token_type} ${response.data.access_token}`);
        dispatch('initTimeoutRefreshToken');
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiRegisterCodeEmail({dispatch}, email) {
      try {
        const response = await apiAxios.post('/users/register_code', {email});
        return response.data;
      }catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiLoginUser({commit, dispatch}, user) {
      try {
        const response = await apiAxios.post('/users/login', user);
        commit('setUser', response.data);
        return response.data;
      } catch (e) {
        dispatch('apiLogoutUser');
        dispatch('catchError', e);
      }
    },
    async apiLoginCodeEmail({dispatch}, [email, password]) {
      try {
        const response = await apiAxios.post('/users/login_code', {email, password});
        return response.data;
      }catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiForgotPassword({dispatch}, email) {
      try {
        const response = await apiAxios.get('/users/remember_password', {email});
        return response.data;
      }catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiResetPassword({dispatch}, data) {
      try {
        const response = await apiAxios.post('/users/set_new_password', data);
        return response.data;
      }catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiLoginUserConfirm({commit, dispatch}, user) {
      try {
        const response = await apiAxios.post('/users/login_confirm', user);
        await commit('setAccessToken', `${response.data.token_type} ${response.data.access_token}`);
        dispatch('initTimeoutRefreshToken');
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },

    async apiLogoutUser({commit}) {
      await commit('clearUser');
      await commit('clearAccessToken');
      await apiAxios.removeToken();
      router.push({name: 'sing-in'});
    },

    async apiUserUploadSSN({dispatch}, [userId, formData]) {
      try {
        const response = await apiAxios.axios.post(`/users/upload_ssn?user_id=${userId}`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiUserUploadIdPhoto({dispatch}, [userId, type_photo,formData]) {
      try {
        const response = await apiAxios.axios.post(`/users/idphoto/?user_id=${userId}&type_photo=${type_photo}`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiUserUploadAvatar({dispatch}, [userId, formData]) {
      try {
        const response = await apiAxios.axios.post(`/users/upload_avatar?user_id=${userId}`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },



    // Me By Access Token

    async apiGetUserMe({commit, dispatch}) {
      try {
        const response = await apiAxios.get('/users/me/');
        await commit('setUser', response.data);
        return response.data;
      } catch (e) {
        dispatch('apiLogoutUser');
        dispatch('catchError', e);
      }
    },
    async apiGetProfileMe({commit, dispatch}) {
      try {
        const response = await apiAxios.get('/users/me/profile/');
        await commit('setProfile', response.data);
        await commit('setAppAPIVersion', response.headers?.['x-api-version']);
        await commit('setAppAMAVersion', response.headers?.['x-api-version']);
        return response.data;
      } catch (e) {
        dispatch('apiLogoutUser');
        dispatch('catchError', e);
      }
    },

    async apiUpdateProfileMe({commit, dispatch}, profile) {
      try {
        const response = await apiAxios.put('/users/me/profile/', profile);
        commit('setProfile', response.data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetProfile({dispatch}, uId) {
      try {
        const response = await apiAxios.get(`/users/${uId}/profile`);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },
    async apiGetProfileWithCache({dispatch, getters, commit}, uId) {
      let profile = getters.userProfileCache[uId];
      if (profile) {
        return profile
      }
      profile = await dispatch('apiGetProfile', uId);
      await commit('setUserProfileCache', [uId, profile]);
      return profile;
    },
    async apiUpdateProfile({dispatch}, [userId, profile]) {
      try {
        const response = await apiAxios.put(`/users/${userId}/profile/`, profile);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },

    async apiFindUsers({commit, dispatch}, params) {
      try {
        const response = await apiAxios.get('/users/find/', params);
        commit('setUsers', response.data);
        return response;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetUser({dispatch}, uId) {
      try {
        const response = await apiAxios.get('/users/' + uId);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return {};
    },

    async apiGetUsers({commit, dispatch}, params) {
      try {
        const response = await apiAxios.get('/users/', params);
        commit('setUsers', response.data);
        return response;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetUserAdmins({commit, dispatch}, params) {
      try {
        const response = await apiAxios.get('/users/admin/', params);
        commit('setUserAdmins', response.data);
        return response;
      } catch (e) {
        dispatch('catchError', e);
      }
    },

    async apiGetVariantRoles({commit, dispatch}) {
      try {
        const response = await apiAxios.get('/users/variant_roles/');
        commit('setRoles', response.data?.roles||[]);
        return response;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiPutRoles({dispatch}, {userId, roles}) {
      try {
        const response = await apiAxios.put(`/users/roles/${userId}`, {roles});
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },


  }
}
