export default {
  mounted() {
    this.showElements();
  },
  updated() {
    this.showElements();
  },
  methods: {
    showElements() {
      setTimeout(()=> {
        document.querySelectorAll('.animate.animate-fade').forEach((elem, idx) =>{
          setTimeout(() => {
            elem.classList.remove('animate-fade');
          },(idx+1) * 80);
        });
      }, 100);
    }
  }
}
