<template>
  <cs-header-mobile></cs-header-mobile>
  <is-preload :preload-type="preloadType" >
    <router-view></router-view>
  </is-preload>
  <cs-footer-mobile></cs-footer-mobile>
</template>

<script>
import CsFooterMobile from "@/layouts/mobile/CsFooterMobile";
import CsHeaderMobile from "@/layouts/mobile/CsHeaderMobile";
import preloadMixin from "@/mixins/preload-mixin";
import IsPreload from "@/components/elements/IsPreload.vue";

export default {
  name: "CsMainMobile",
  components: {
    CsFooterMobile,
    CsHeaderMobile,
    IsPreload
  },
  mixins: [preloadMixin],
  props: {
    preloadType: {
      default: 'main-data',
    }
  },
  async created() {
    await this.preloadSwitcher(this.preloadType, async ()=>{
      try {
        await this.$store.dispatch('apiGetUserData');
        await this.$store.dispatch('apiGetBureaus', {});
        this.$store.dispatch('apiLoadOrderMe');
        this.$store.dispatch('apiGetUserDataTypes', {});
        this.$store.dispatch('apiGetDisputeTypes');
      }catch (e){
        this.$router.push({name: 'sing-in'});
      }
    })
  },
}
</script>

<style scoped>

</style>
