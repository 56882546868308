import helper from "@/helpers/helper";

export default {
  mixins: [helper],
  computed: {
    carts() {
      return this.$store.getters.carts;
    },
    order() {
      return this.$store.getters.order;
    },
    openOrders() {
      return this.$store.getters.orders.open;
    },
    successOrders() {
      return this.$store.getters.orders.success;
    },
    currentOrder() {
      if (this.carts.length) {
        return this.carts[0];
      }
      return {};
    },
    currentOrderId() {
      return this.currentOrder?.id;
    },
    currentOrderNumber() {
      return this.orderNumber(this.currentOrder);
    },
    currentOrderTotal() {
      return this.currentOrder?.cost||0;
    },
    disputes() {
      return this.order?.disputes||{};
    },
    cartCount() {
      let count = 0;
      Object.values(this.disputes).forEach(dispute=>{
        count += dispute.length;
      });
      return count;
    },
    tasks() {
      let tasks = [];
      Object.values(this.disputes).forEach(async disputes=> {
        await disputes.forEach(async dispute => {
          tasks.push({name:dispute.name, cost:dispute.cost})
        })
      });
      return tasks;
    },
    isEmptyCart(){
      return Object.values(this.disputes).length===0;
    },

    orderStatuses() {
      return this.$store.getters.orderStatuses;
    },

    orderProcessStatus() {
      return ['CREATED', 'PENDING', 'PAYMENTERROR', 'PAYMENTSUCCESS', 'PROCESSING', 'ACTION', 'FALSE'];
    },
    orderSuccessStatus() {
      return ['SUCCESS'];
    }
  },
  methods: {
    async getLastOrders(userId) {
      let dispatch = 'apiGetOpenOrders';
      const params = {limit: 5, status:this.orderProcessStatus};
      if (userId === this.$store.getters.user.id){
        dispatch = 'apiGetOpenOrdersMe'
      }else {
        params.user_id = userId;
      }
      await this.$store.dispatch(dispatch, params);
    }
  }
}
