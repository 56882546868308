<template>
  <ul class="links">
    <li v-for="link in links" :key="link.$uid">
      <router-link :to="link.url"><a>{{ link.title }}</a></router-link>
    </li>
  </ul>
</template>

<script>

export default {
  name: "CsMenuLink",
  data() {
    return {
      links: [
        {
          title: "First Link",
          url: "/first-link"
        },
        {
          title: "Second Link",
          url: "/second-link"
        },
        {
          title: "Third Link",
          url: "/third-link"
        },
        {
          title: "Fourth Link",
          url: "/fourth-link"
        }
      ]
    };
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
