<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "CsVersions",
  computed: {
    appWEBVersion() {
      return process.env.VUE_APP_WEB_VERSION;
    },
    appAPIVersion() {
      return this.$store.getters.appAPIVersion;

    },
    appAMAVersion() {
      return this.$store.getters.appAMAVersion;
    }
  }
})
</script>

<template>
  <div class="version-text">
    <p>Versions
      <br><span v-if="appWEBVersion">WEB: {{appWEBVersion}}</span>
      <br><span v-if="appAPIVersion">API: {{appAPIVersion}}</span>
      <br><span v-if="appAMAVersion">AMA: {{appAMAVersion}}</span>
    </p>
  </div>
</template>

<style scoped>
.version-text {
  color: #8e8e8e;
  font-weight: 300;
  font-size: 13px;
  margin-top: 1rem;
}
.version-text p span {
  margin-inline: 1rem;
}
</style>
