<template>
  <cs-header-mobile></cs-header-mobile>
  <router-view></router-view>
  <cs-footer-mobile></cs-footer-mobile>
</template>

<script>
import CsFooterMobile from "@/layouts/mobile/CsFooterMobile";
import CsHeaderMobile from "@/layouts/mobile/CsHeaderMobile";

export default {
  name: "CsGuestMainMobile",
  components: {
    CsFooterMobile,
    CsHeaderMobile
  },
}
</script>

<style scoped>

</style>
