import apiAxios from "@/api-axios";
import qs from "qs";

export default {
  state: {
    orders: {
      open:[],
      success:[],
    },
    carts: [],
    order: {},
    orderDetails: {},
    orderStatuses: {},
    openDisputeDetail: '',
  },
  mutations: {
    setOrders(state, [type, orders]) {
      state.orders[type] = orders;
    },
    addOrders(state, [type, orders]) {
      state.orders[type].push(...orders);
    },
    addOrder(state, [type, order]) {
      state.orders[type].push(order);
    },
    clearOrder(state, type) {
      state.orders[type] = [];
    },
    clearOrders(state) {
      state.orders = {};
    },

    setCart(state, cart) {
      state.carts = cart;
    },
    setOrder(state, order) {
      state.order = order;
    },
    setOrderDetails(state, orderDetails) {
      state.orderDetails = orderDetails;
    },
    setOrderStatuses(state, [id, data]) {
      state.orderStatuses[id] = data;
    },
    setOpenDisputeDetail(state, disputeId) {
      state.openDisputeDetail = disputeId;
    },
  },
  getters: {
    orders: s => s.orders,
    carts: s => s.carts,
    order: s => s.order,
    orderDetails: s => s.orderDetails,
    orderStatuses: s => s.orderStatuses,
    openDisputeDetail: s => s.openDisputeDetail,
  },
  actions: {
    async apiGetOrderData({dispatch}, id) {
      let data = {};
      try {
        const response = await apiAxios.get(`/orders/${id}`);
        data = response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return data;
    },
    async apiGetClientOrderData({dispatch}, id) {
      let data = {};
      try {
        const response = await apiAxios.get(`/orders/${id}/me`);
        data = response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return data;
    },
    async apiGetClientOrderDetails({commit, dispatch}, id) {
      const data = await dispatch('apiGetClientOrderData', id);
      commit('setOrderDetails', data);
    },
    async apiGetOrderDetails({commit, dispatch}, id) {
      const data = await dispatch('apiGetOrderData', id);
      commit('setOrderDetails', data);
    },
    async apiGetOrder({commit, dispatch}, id) {
      const data = await dispatch('apiGetOrderData', id);
      commit('setOrder', data);
    },
    async apiGetOrderStatus({commit, dispatch}, id) {
      try {
        const response = await apiAxios.get(`/orders/${id}/status`);
        commit('setOrderStatuses', [id, response.data]);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetOrders({commit, dispatch}, {type, params}) {
      try {
        let paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' })
        const response = await apiAxios.get('/orders/', params, {paramsSerializer});
        commit('setOrders', [type, response.data]);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetCountOrders({dispatch}, params) {
      try {
        let paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' })
        const response = await apiAxios.get('/orders/count/', params, {paramsSerializer});
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
      return 0;
    },

    async apiGetOpenOrders({dispatch}, params) {
      await dispatch('apiGetOrders', {type:'open', params});
    },
    async apiGetSuccessOrders({dispatch}, params) {
      await dispatch('apiGetOrders', {type:'success', params});
    },
    async apiGetOrdersMe({commit, dispatch}, {type, params}) {
      try {
        let paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' })
        const response = await apiAxios.get('/orders/me/', params, {paramsSerializer});
        commit('setOrders', [type, response.data]);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetOpenOrdersMe({dispatch}, params) {
      await dispatch('apiGetOrdersMe', {type:'open', params});
    },
    async apiGetSuccessOrdersMe({dispatch}, params) {
      await dispatch('apiGetOrdersMe', {type:'success', params});
    },
    async apiGetCartMe({commit, dispatch}, params) {
      try {
        const response = await apiAxios.get('/orders/me/', params);
        commit('setCart', response.data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiPatchOrder({dispatch}, {id, params}) {
      try {
        return await apiAxios.patch(`/orders/${id}`, params);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiPayment({dispatch}, {id, type}) {
      try {
        return await apiAxios.get(`/payment/${id}/${type}`);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiLoadOrderMe({dispatch, commit, getters}) {
      await dispatch('apiGetBureaus');
      await dispatch('apiGetCartMe', {limit: 1, status: 'CREATING'});
      if (getters.carts.length) {
        await dispatch('apiGetOrder', getters.carts[0].id);
      }else{
        await commit('setOrder', {});
      }
    },
  }

}
