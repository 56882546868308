
export default {
  state: {
    blobCache: {}
  },
  mutations: {
    setCacheData(state, {key, data}) {
      state.blobCache[key] = data;
      setTimeout(()=>{
        if (state.blobCache?.[key]) {
          delete state.blobCache[key];
        }
      }, 60000)
    },
    clearCacheKey(state, key) {
      if (state.blobCache?.[key]) {
        delete state.blobCache[key];
      }
    }
  },
  getters: {
    blobCache: s=>s.blobCache
  }
}
