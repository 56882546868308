<template>
  <div :class="{'app-desktop':!isMobile, 'app-mobile':isMobile}">
    <component :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
import CsMain  from "@/layouts/CsMain";
import CsGuestMain  from "@/layouts/CsGuestMain";
import CsMainMobile from "@/layouts/mobile/CsMainMobile";
import CsGuestMainMobile  from "@/layouts/mobile/CsGuestMainMobile";


export default {
  name: "App",
  components: {CsMain, CsGuestMain, CsMainMobile, CsGuestMainMobile},
  computed: {
    getMessages(){
      return this.$store.getters.messages;
    },
    isMobile() {
      return this.$isMobile();
    },
    layout() {
      if (this.isMobile){
        return this.$route.meta.layout+'-mobile';
      }
      return this.$route.meta.layout;
    },
  },
  async mounted() {
    if (this.isMobile) {
      await import("@/assets/css/mobile.css");
    } else {
      await import("@/assets/css/main.css");
    }
  },
  watch: {
    getMessages: {
      handler(newValue) {
        Object.keys(newValue).forEach(type => {
          if (!newValue[type]) {
            return;
          }
          let options = {position: this.isMobile?'top':'top-right'};
          switch (type){
            case 'error':
              this.$toast.error(newValue[type], options);
              this.$store.commit('clearMessage', type)
              break;
            case 'success':
              this.$toast.success(newValue[type], options);
              this.$store.commit('clearMessage', type)
              break;
            case 'info':
              this.$toast.info(newValue[type], options);
              this.$store.commit('clearMessage', type)
              break;
            case 'warning':
              this.$toast.warning(newValue[type], options);
              this.$store.commit('clearMessage', type)
              break;
            default:
              this.$toast.default(newValue[type], options);
              this.$store.commit('clearMessage', type)
          }
        });
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
