import apiAxios from "@/api-axios";

export default {
  state: {
    users: [],
    userAdmins: [],
    roles: [],
    accessToken: null,
    accessTokenData: null,
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setUserAdmins(state, userAdmins) {
      state.userAdmins = userAdmins;
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
      if (state.accessToken.indexOf('.')!==-1) {
        let decode = JSON.parse(atob(state.accessToken.split('.')[1]));
        if (decode) {
          state.accessTokenData = decode;
        }
      }
      apiAxios.setTokenHeader();
    },
    clearAccessToken(state) {
      state.accessToken = null;
      state.accessTokenData = null;
      apiAxios.removeToken();
    }
  },
  getters: {
    accessToken: s => s.accessToken,
    accessTokenData: s => s.accessTokenData,
    users: s => s.users,
    userAdmins: s => s.userAdmins,
    roles: s => s.roles
  },
  actions: {
    initTimeoutRefreshToken({dispatch}) {
      setTimeout(() => {
        dispatch('apiRefreshToken');
      }, 15 * 60 * 1000)
    },
    async apiRefreshToken({commit, dispatch}) {
      try {
        let data = {};
        if (this.getters.accessToken) {
          const response = await apiAxios.post('/users/refresh_token');
          await commit('setAccessToken', `${response.data.token_type} ${response.data.access_token}`);
          data = response.data;
        }
        dispatch('initTimeoutRefreshToken');
        return data;
      } catch (e) {
        dispatch('apiLogoutUser');
        dispatch('catchError', e);
        throw e.getMessages();
      }
    },
    async apiGetUserData({dispatch}) {
      try {
        await dispatch('apiRefreshToken');
        await dispatch('apiGetUserMe');
        await dispatch('apiGetProfileMe');
      }catch (e) {
        throw e.getMessages();
      }
    }
  }
}
