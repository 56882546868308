<template>
  <div class="preloader-progress">
    <div class="preloader-progress-ico">
      <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 41 41" fill="none">
        <path d="M32.1623 6.00761C32.8195 5.19102 34.0213 5.05581 34.7737 5.78561C37.7808 8.70258 39.8338 12.4836 40.6309 16.6274C41.548 21.3949 40.7434 26.3331 38.3603 30.5628C35.9772 34.7925 32.1703 38.0392 27.6174 39.7248C23.0645 41.4104 18.0612 41.4254 13.4983 39.7672C8.93538 38.1091 5.10903 34.8853 2.70054 30.67C0.29205 26.4547 -0.542254 21.5214 0.346178 16.7485C1.23461 11.9757 3.78811 7.67297 7.55203 4.6066C10.8236 1.94134 14.825 0.360465 19.0033 0.0546731C20.0487 -0.021833 20.8833 0.853441 20.8629 1.9014V1.9014C20.8424 2.94937 19.9738 3.77113 18.9303 3.86961C15.6526 4.17895 12.5237 5.45218 9.94945 7.54938C6.88245 10.048 4.80174 13.554 4.07781 17.4431C3.35388 21.3323 4.0337 25.3521 5.99625 28.787C7.95879 32.2218 11.0767 34.8486 14.7947 36.1998C18.5128 37.5509 22.5897 37.5386 26.2996 36.1652C30.0094 34.7917 33.1115 32.1462 35.0533 28.6996C36.9952 25.2531 37.6508 21.2292 36.9035 17.3445C36.2763 14.0838 34.6948 11.0989 32.3804 8.75739C31.6436 8.01193 31.5052 6.82421 32.1623 6.00761V6.00761Z" fill="url(#paint0_angular_366_7222)"/>
        <defs>
          <radialGradient id="paint0_angular_366_7222" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.5 0) rotate(-70) scale(37.33)">
            <stop stop-color="#107DF6" stop-opacity="0"/>
            <stop offset="1" stop-color="#107DF6"/>
          </radialGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreloaderProgress",
  props: {
    width: {
      type: Number,
      default: 41
    },
    height: {
      type: Number,
      default: 41
    },
  }
}
</script>

<style scoped>
.preloader-progress-ico {
  width: 40px;
  height: 40px;
}
.preloader-progress{
  width: 100%;
  display: inline-flex;
  padding: 0;
  justify-content: center;
}
</style>
