import {createStore} from "vuex";

import servicesApi from '@/services/api';
import usersApi from '@/services/users';
import bureausApi from '@/services/bureaus';
import tasksApi from '@/services/tasks';
import userDataApi from '@/services/userData';
import reportsApi from "@/services/reports";
import analizersApi from "@/services/analizers";
import disputes from "@/services/disputes";
import orders from "@/services/orders";
import customers from "@/services/customers";
import reportNumbers from "@/services/reportNumbers";
import disputeTemplates from "@/services/disputeTemplates";
import blobCache from "@/services/blobCache";
import transports from "@/services/transports";


export default createStore({
  state: {
    message:{
      info: null,
      success: null,
      warning: null,
      error: null
    },
    preloader: {}
  },
  mutations: {
    setError(state, error) {
      state.message.error = error
    },
    clearError(state) {
      state.message.error = null
    },
    setMessage(state, [type, message]) {
      state.message[type] = message
    },
    clearMessage(state, type) {
      state.message[type] = null
    },
    onPreloader(state, type) {
      state.preloader[type] = true;
    },
    offPreloader(state, type) {
      state.preloader[type] = false;
    },
  },
  getters: {
    error: s => s.message.error,
    messages: s => s.message,
    preloader: s => s.preloader,
  },
  actions: {
    catchError({commit}, error) {
      console.error("catchError:", error);
      if (error.response.data === undefined) {
        commit('setError', error.message);
      }else {
        if (typeof error.response.data.detail === 'string') {
          commit('setError', error.response.data.detail);
        } else {
          if (typeof error.response.data.detail === 'object') {
            error.response.data.detail.forEach(item => {
              commit('setError', `location: ${item.loc.join(', ')}<br> message: ${item.msg}`);
            });
          } else {
            commit('setError', error);
          }
        }
      }
      throw error;
    },
  },
  modules: {
    servicesApi, usersApi, userDataApi, reportsApi,
    analizersApi, bureausApi, tasksApi, disputes,
    orders, customers, reportNumbers, disputeTemplates,
    blobCache, transports
  }
});
