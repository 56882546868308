export default {
  toggleMainMenu() {
    let mainMenuWrapper = document.querySelector('.main-menu-wrapper');
    let mainMenu = document.querySelector('.main-menu');

    mainMenuWrapper.classList.toggle('active');

    if (mainMenuWrapper.className.indexOf('active') !== -1) {
      mainMenuWrapper.style.display = 'block';
      window.setTimeout(() => {
        mainMenuWrapper.style.opacity = 1;
        mainMenu.classList.add('active');
      }, 100);
    } else {
      mainMenu.classList.remove('active');
      mainMenuWrapper.style.opacity = 0;

      window.setTimeout(() => {
        mainMenuWrapper.style.display = 'none';
      }, 1000);
    }

  }
}
