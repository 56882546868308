import {createRouter, createWebHistory} from "vue-router";
import store from "@/store";
import {useMobileDetection} from "vue3-mobile-detection";

const MOBILE_POSTFIX = useMobileDetection().isMobile()?'Mobile':'';

function guardRoute(to, from, next) {
    if (to.meta.auth && !store.getters.accessToken) {
      if (localStorage === undefined || !localStorage.getItem('token')) {
        next({name: 'sing-in'});
      }else{
        store.commit('setAccessToken', localStorage.getItem('token'))
        store.dispatch('apiRefreshToken');
        next();
      }
    } else {
        let names = ['sing-in', 'sing-up', 'forgot-password'];
        if (names.indexOf(to.name) !== -1 && store.getters.accessToken) {
            next({name: 'dashboard'});
        }else {
            next();
        }
    }
}

// function scrollBehavior(to) {
//   // always scroll 10px above the element #main
//   if (to.hash) {
//     return {
//       // could also be
//       // el: document.getElementById('main'),
//       el: to.hash,
//       top: -10,
//     }
//   }
// }

const operatorRoutes = [
  {
    path: '/operator-dashboard',
    name: 'operator-dashboard',
    meta: {
      auth: true,
      content: 'cs-operator-dashboard',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/operator-reports',
    name: 'operator-reports',
    meta: {
      auth: true,
      content: 'cs-operator-reports',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/operator-clients',
    name: 'operator-clients',
    meta: {
      auth: true,
      content: 'cs-operator-clients',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/operator-report-numbers',
    name: 'operator-report-numbers',
    meta: {
      auth: true,
      content: 'cs-operator-report-numbers',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/operator-orders',
    name: 'operator-orders',
    meta: {
      auth: true,
      content: 'cs-operator-orders',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
];

const adminRoutes = [
  {
    path: '/admin-dashboard',
    name: 'admin-dashboard',
    meta: {
      auth: true,
      content: 'cs-admin-dashboard',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/settings-list',
    name: 'settings-list',
    meta: {
      auth: true,
      content: 'cs-settings-list',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/setting-reasons',
    name: 'setting-reasons',
    meta: {
      auth: true,
      content: 'cs-setting-reasons',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/dispute-templates',
    name: 'dispute-templates',
    meta: {
      auth: true,
      content: 'cs-dispute-templates',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/transports',
    name: 'transports',
    meta: {
      auth: true,
      content: 'cs-transports',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/directory',
    name: 'directory',
    meta: {
      auth: true,
      content: 'cs-directory',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/operators',
    name: 'operators',
    meta: {
      auth: true,
      content: 'cs-operators',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/admins',
    name: 'admins',
    meta: {
      auth: true,
      content: 'cs-admins',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
];

const clientRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      auth: true,
      content: 'cs-dashboard',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/reports',
    name: 'reports',
    meta: {
      auth: true,
      content: 'cs-reports',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/orders',
    name: 'orders',
    meta: {
      auth: true,
      content: 'cs-orders',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/account-setup',
    name: 'account-setup',
    meta: {
      auth: true,
      content: 'cs-setup',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/connect-bureau',
    name: 'connect-bureau',
    meta: {
      auth: true,
      content: 'cs-connect-service',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/cart',
    name: 'cart',
    meta: {
      auth: true,
      content: 'cs-cart',
      layout:'cs-main',
    },
    component: () => import(`@/views/CsAccount${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },

];

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      auth: false,
      layout: "cs-guest-main",
    },
    component: () => import(`@/views/CsHome${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/sing-in',
    name: 'sing-in',
    meta: {
      auth: false,
      isShowHeader: false,
      layout: "cs-guest-main",
    },
    component: () => import(`@/views/CsSingIn${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/sing-up',
    name: 'sing-up',
    meta: {
      auth: false,
      isShowHeader: false,
      layout: "cs-guest-main",
    },
    component: () => import(`@/views/CsSingUp${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: {
      auth: false,
      layout: "cs-guest-main",
    },
    component: () => import(`@/views/CsForgotPassword${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/first-link',
    name: 'first-link',
    meta: {
      auth: false,
      layout: "cs-guest-main",
    },
    component: () => import(`@/views/CsHome${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/second-link',
    name: 'second-link',
    meta: {
      auth: false,
      layout: "cs-guest-main",
    },
    component: () => import(`@/views/CsHome${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/third-link',
    name: 'third-link',
    meta: {
      auth: false,
      layout: "cs-guest-main",
    },
    component: () => import(`@/views/CsHome${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  {
    path: '/fourth-link',
    name: 'fourth-link',
    meta: {
      auth: false,
      layout: "cs-guest-main",
    },
    component: () => import(`@/views/CsHome${MOBILE_POSTFIX}`),
    beforeEnter: [guardRoute],
  },
  ...clientRoutes,
  ...operatorRoutes,
  ...adminRoutes,
];

const index = createRouter({
  routes,
  // scrollBehavior,
  linkActiveClass: 'active',
  history: createWebHistory(process.env.BASE_URL)
});


// index.beforeEach((to, from, next) => {
//   const currentUser = localStorage.getItem('token');
//   const requiresAuth = to.matched.some(record => record.meta.auth);
//
//   if (requiresAuth && !currentUser) {
//     next("/sing-in");
//   }else {
//     if (currentUser && (to.path == "/sing-in" || to.path == "/sing-up")) {
//       next("/dashboard");
//     }
//   }
//   next();
// });

export default index;
