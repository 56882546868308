import apiAxios from "@/api-axios";

export default {
  store: {
    reportNumbers: [],
    reportNumbersCount: 0,
    reportNumbersUser: [],
    reportNumbersCountUser: 0,
  },
  mutations: {
    setReportNumbers(state, {count, numbers}) {
      state.reportNumbers = numbers;
      state.reportNumbersCount = count;
    },
    setReportNumbersUser(state, {count, numbers}) {
      state.reportNumbersUser = numbers;
      state.reportNumbersCountUser = count;
    },
  },
  getters: {
    reportNumbers: s=>s.reportNumbers,
    reportNumbersCount: s=>s.reportNumbersCount,
    reportNumbersUser: s=>s.reportNumbersUser,
    reportNumbersCountUser: s=>s.reportNumbersCountUser,
  },
  actions: {

    async apiGetReportNumbers({commit, dispatch}, params) {
      try {
        const response = await dispatch('apiGetReportNumbersRequest', params);
        commit('setReportNumbers', response.data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetReportNumbersUser({commit, dispatch}, params) {
      try {
        const response = await dispatch('apiGetReportNumbersRequest', params);
        commit('setReportNumbersUser', response.data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetReportNumbersRequest(_, params) {
      return await apiAxios.get('/report_numbers/', params);
    },
  }
}
