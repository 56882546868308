import apiAxios from "@/api-axios";

export default {
  state: {
    tasks: [],
  },
  mutations: {
    setTasks(state, tasks) {
      state.tasks.push(...tasks);
    },
    addTask(state, task) {
      state.tasks.push(task);
    },
    clearTasks(state) {
      console.log("clearTasks");
      state.tasks = [];
    },
  },
  getters: {
    tasks: s => s.tasks,
  },
  actions: {
    async apiGetTasks({commit, dispatch}) {
      try {
        const response = await apiAxios.get('/tasks/');
        commit('setTasks', response.data);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    
    async apiGetTasksMe({commit, dispatch}, params) {
      try {
        const response = await apiAxios.get('/tasks/me/', params);
        commit('setTasks', response.data);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    }
  }

}
