<template>

  <template v-if="social === 'facebook'">
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H11.8808V15.1578H9.516V12.4913H11.8808V10.5249C11.8808 8.2427 13.3119 7 15.403 7C16.4047 7 17.265 7.07288 17.516 7.10528V9.49055L16.066 9.49129C14.929 9.49129 14.709 10.0177 14.709 10.7892V12.492H17.4207L17.0677 15.1585H14.709V22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6Z" fill="#BBBBBB"/>
    </svg>
  </template>

  <template v-else-if="social === 'instagram'">
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C9.28417 2 8.94417 2.01167 7.8775 2.06C4.24583 2.22667 2.2275 4.24167 2.06083 7.87667C2.01167 8.94417 2 9.28417 2 12C2 14.7158 2.01167 15.0567 2.06 16.1233C2.22667 19.755 4.24167 21.7733 7.87667 21.94C8.94417 21.9883 9.28417 22 12 22C14.7158 22 15.0567 21.9883 16.1233 21.94C19.7517 21.7733 21.775 19.7583 21.9392 16.1233C21.9883 15.0567 22 14.7158 22 12C22 9.28417 21.9883 8.94417 21.94 7.8775C21.7767 4.24917 19.7592 2.2275 16.1242 2.06083C15.0567 2.01167 14.7158 2 12 2ZM12 6.865C9.16417 6.865 6.865 9.16417 6.865 12C6.865 14.8358 9.16417 17.1358 12 17.1358C14.8358 17.1358 17.135 14.8367 17.135 12C17.135 9.16417 14.8358 6.865 12 6.865ZM12 15.3333C10.1592 15.3333 8.66667 13.8417 8.66667 12C8.66667 10.1592 10.1592 8.66667 12 8.66667C13.8408 8.66667 15.3333 10.1592 15.3333 12C15.3333 13.8417 13.8408 15.3333 12 15.3333ZM17.3383 5.4625C16.675 5.4625 16.1375 6 16.1375 6.6625C16.1375 7.325 16.675 7.8625 17.3383 7.8625C18.0008 7.8625 18.5375 7.325 18.5375 6.6625C18.5375 6 18.0008 5.4625 17.3383 5.4625Z" fill="#BBBBBB"/>
    </svg>
  </template>

  <template v-else-if="social === 'twitter'">
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22 5.92417C21.2642 6.25083 20.4733 6.47083 19.6433 6.57C20.4908 6.0625 21.1417 5.25833 21.4475 4.3C20.655 4.77 19.7767 5.11167 18.8417 5.29583C18.0942 4.49833 17.0267 4 15.8467 4C13.1975 4 11.2508 6.47167 11.8492 9.0375C8.44 8.86667 5.41667 7.23333 3.3925 4.75083C2.3175 6.595 2.835 9.0075 4.66167 10.2292C3.99 10.2075 3.35667 10.0233 2.80417 9.71583C2.75917 11.6167 4.12167 13.395 6.095 13.7908C5.5175 13.9475 4.885 13.9842 4.24167 13.8608C4.76333 15.4908 6.27833 16.6767 8.075 16.71C6.35 18.0625 4.17667 18.6667 2 18.41C3.81583 19.5742 5.97333 20.2533 8.29 20.2533C15.9083 20.2533 20.2125 13.8192 19.9525 8.04833C20.7542 7.46917 21.45 6.74667 22 5.92417Z" fill="#BBBBBB"/>
    </svg>
  </template>

  <template v-else-if="social === 'youtube'">
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3458 5.15344C15.3425 4.94844 8.65333 4.94927 5.65417 5.15344C2.40667 5.3751 2.02417 7.33677 2 12.5001C2.02417 17.6543 2.40333 19.6243 5.65417 19.8468C8.65417 20.0509 15.3425 20.0518 18.3458 19.8468C21.5933 19.6251 21.9758 17.6634 22 12.5001C21.9758 7.34594 21.5967 5.37594 18.3458 5.15344ZM9.5 15.8334V9.16677L16.1667 12.4943L9.5 15.8334V15.8334Z" fill="#BBBBBB"/>
    </svg>
  </template>

</template>

<script>
export default {
  name: 'IconSocial',
  props: {
    social: {
      type: String,
      default: 'facebook',
    },
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
    
  }
}
</script>
