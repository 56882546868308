<template>
  <cs-header v-show="isShowHeader" />
  <router-view></router-view>
  <cs-footer v-show="isShowHeader" />
</template>

<script>
import CsFooter from "@/layouts/CsFooter";
import CsHeader from "@/layouts/CsHeader";

export default {
  name: "CsGuestMain",
  components: {
    CsFooter,
    CsHeader
  },
  computed: {
    isShowHeader() {
      if (this.$route.meta?.isShowHeader===undefined){
        return true
      }
      return this.$route.meta.isShowHeader;
    }
  },
}
</script>

<style scoped>

</style>
