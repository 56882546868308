import apiAxios from "@/api-axios";

export default {
  store: {
    transportsData: [],
    transportData: {},
    transportsType: [],
  },
  mutations: {
    setTransportsData(state, data) {
      state.transportsData = data;
    },
    setTransportData(state, data) {
      state.transportData = data;
    },
    setTransportsType(state, data) {
      state.transportsType = data;
    },
  },
  getters: {
    transportsData: s=>s.transportsData,
    transportData: s=>s.transportData,
    transportsType: s=>s.transportsType
  },
  actions: {
    async apiGetTransports ({dispatch, commit}){
      try {
        const response = await apiAxios.get(`/transports/`);
        commit('setTransportsData', response.data);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetTransportsType ({dispatch, commit}){
      try {
        const response = await apiAxios.get(`/transports/types`);
        commit('setTransportsType', response.data);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiCreateTransports({dispatch}, data) {
      try {
        return await apiAxios.post(`/transports/`, data);
      }catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiPutTransports({dispatch}, {transport_id, data}) {
      try {
        return await apiAxios.put(`/transports/${transport_id}`, data);
      }catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetTransport({dispatch, commit}, transport_id) {
      try {
        const response = await apiAxios.get(`/transports/${transport_id}`);
        commit('setTransportData', response.data);
      }catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiDeleteTransport({dispatch}, transport_id) {
      try {
        return await apiAxios.delete(`/transports/${transport_id}`);
      }catch (e) {
        dispatch('catchError', e);
      }
    },
  }
}
