import apiAxios from "@/api-axios";

const url = '/dispute_templates/';

export default {
  state: {
    disputeTemplates: [],
    disputeTemplate: {},
  },
  mutations: {
    setDisputeTemplates(state, data) {
      state.disputeTemplates = data;
    },
    setDisputeTemplate(state, data) {
      state.disputeTemplate = data;
    }
  },
  getters: {
    disputeTemplates: s=>s.disputeTemplates,
    disputeTemplate: s=>s.disputeTemplate,
  },
  actions: {
    async apiGetDisputeTemplates({dispatch, commit}) {
      try {
        const response = await apiAxios.get(url);
        commit('setDisputeTemplates', response.data);
        return response;
      }catch (e){
        commit('setDisputeTemplates', []);
        dispatch('catchError', e);
      }
    },
    async apiCreateDisputeTemplates({dispatch}, data) {
      try {
        return await apiAxios.post(`${url}`, data);
      }catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetDisputeTemplate({dispatch, commit}, dispute_template_id) {
      try {
        const response = await apiAxios.get(`${url}${dispute_template_id}`);
        commit('setDisputeTemplate', response.data);
        return response;
      }catch (e) {
        commit('setDisputeTemplate', {});
        dispatch('catchError', e);
      }
    },
    async apiPatchDisputeTemplate({dispatch}, {dispute_template_id, data}) {
      try {
        return await apiAxios.patch(`${url}${dispute_template_id}`, data);
      }catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiDeleteDisputeTemplate({dispatch}, dispute_template_id) {
      try {
        return await apiAxios.delete(`${url}${dispute_template_id}`);
      }catch (e) {
        dispatch('catchError', e);
      }
    },
  }
}
