import apiAxios from "@/api-axios";

export default {
  state: {
    analizersReportUserData: {},
    analizersUserBureausData: {},
  },
  mutations: {
    setAnalizerReportUserData(state, [reportId, analizerReportUserData]) {
      if (!state.analizersReportUserData[reportId]) {
        state.analizersReportUserData[reportId] = '';
      }
      state.analizersReportUserData[reportId] = analizerReportUserData;
    },
    clearAnalizerReportUserData(state) {
      state.analizersReportUserData = {};
    },
    setAnalizersUserBureausData(state, [reportId, analizersUserBureausData]) {
      if (!state.analizersUserBureausData[reportId]) {
        state.analizersUserBureausData[reportId] = [];
      }
      state.analizersUserBureausData[reportId] = analizersUserBureausData;
    },
    clearAnalizersUserBureausData(state) {
      state.analizersUserBureausData = {};
    }
  },
  getters: {
    analizersReportUserData: s => s.analizersReportUserData,
    analizersUserBureausData: s => s.analizersUserBureausData,
  },
  actions: {
    async apiGetAnalizersReportUserData({commit, dispatch}, reportId) {
      try {
        const response = await apiAxios.get(`/analizers/report/${reportId}/userdata/`);
        commit('setAnalizerReportUserData', [reportId, response.data]);
        return response.data;
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetAnalizersUserBureausData({commit, dispatch}, [user_id, user_data_code]) {
      try {
        const response = await apiAxios.get(`/analizers/user_bureaus/${user_id}/${user_data_code}`);
        commit('setAnalizersUserBureausData', [user_data_code, response.data]);
      } catch (e) {
        dispatch('catchError', e);
      }
    }
  },
}
