import helper from "@/helpers/helper";

export default {
  mixins: [helper],
  data(){
    return {
      blobAvatar: this.defaultImage(),
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    profile() {
      return this.$store.getters.profile;
    },
    userId() {
      return this.user.id||'';
    },
    userFullName(){
      return this.profile.first_name + " " + this.profile.middle_name + " " + this.profile.second_name;
    },
    userEmail(){
      return this.user.email;
    },
    userEmailAddress(){
      return this.profile.email_address;
    },
    avatarUrl(){
      return this.imageToUrl(this.profile, 'avatar_file');
    },
    accessToken() {
      return this.$store.getters.accessToken;
    },
    isAuth() {
      return this.accessToken!==null;
    },
    userTokenData() {
      return this.$store.getters.accessTokenData;
    },
    userRoles() {
      return this.userTokenData?.roles||[];
    },
    isAdmin() {
      return this.userRoles.indexOf('admin') !== -1;
    },
    isOperator() {
      return this.userRoles.indexOf('operator') !== -1;
    },
    isClient() {
      return this.userRoles.indexOf('me') !== -1||this.userRoles.indexOf('users') !== -1;
    },
    isSendDispute() {
      //todo: remove
      return true;
      // if (this.profile){
      //   return this.profile.ssn.length&&this.profile.id_photo_front_file?.filename&&this.profile.id_photo_back_file?.filename;
      // }
      // return false;
    }
  },
  methods: {
    async loadBlobAvatar() {
      console.log('loadBlobAvatar')
      this.loadBlobJWT(this.avatarUrl).then(blobData => {
        this.blobAvatar = blobData;
      });
    }
  }
}
