<template>
  <header class="header">
    <button class="btn-toggle-menu" :class="{active:toggleActiveMainMenu}" @click="toggleMainMenu">
      <span class="line-1"></span>
      <span class="line-2"></span>
    </button>
    <div class="container">
      <div class="row justify-content-center">
        <div class="shrink">
          <div class="logo"><router-link to="/"><img src="@/assets/images/logo-header.svg" alt=""></router-link></div>
        </div>
      </div>
    </div>

    <div class="main-menu-wrapper">
      <div class="main-menu">
        <template v-if="isAuth">
          <div class="user-wrap">
            <div class="user-avatar"><img :src="avatarUrl" alt=""></div>
            <div>
              <div class="user-name">{{ userFullName }}</div>
              <div class="user-email">{{ userEmail }}</div>
            </div>
          </div>
          <cs-sidebar-mobile @clickRouteLink="toggleMainMenu" />
        </template>
        <template v-else>
          <ul class="list-menu">
            <li :class="{active:'/sing-in'===this.$route.path}">
              <router-link :to="{name:'sing-in'}" @click="toggleMainMenu"><span class="ico"></span><span class="name">Sign in</span></router-link>
            </li>
            <li :class="{active:'/sing-up'===this.$route.path}">
              <router-link :to="{name:'sing-up'}" @click="toggleMainMenu"><span class="ico"></span><span class="name">Sign up</span></router-link>
            </li>
          </ul>
        </template>
      </div>
    </div>
  </header>
</template>

<script>
import CsSidebarMobile from "@/layouts/mobile/CsSidebarMobile";
import btnToggleMenu from "@/views/exports/btn-toggle-menu";
import userMixin from "@/mixins/user-mixin";

export default {
  name: "CsHeaderMobile",
  mixins: [userMixin],
  components: {
    CsSidebarMobile
  },
  data() {
    return {
      toggleActiveMainMenu: false,
    };
  },
  methods: {
    toggleMainMenu() {
      this.toggleActiveMainMenu=!this.toggleActiveMainMenu;
      btnToggleMenu.toggleMainMenu()
    }
  }
}
</script>

<style scoped>

</style>
