<template>
  <ul class="list-menu">
    <li v-for="link in links" :key="link.$uid" :class="{active:link.url===this.$route.path, warning:link.warning}">
      <router-link
          :key="link.$uid"
          :to="link.url"
          :exact="link.exact"
          @click="$emit('clickRouteLink')"
      >
        <span v-html="link.icon" class="ico"></span><span class="name">{{link.title}}</span>
      </router-link>
    </li>
    <li>
      <a href="" @click.prevent="logout">
        <span class="ico">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 7.99998V11H5.82998L8.70998 8.11998C9.09998 7.72998 9.09998 7.09998 8.70998 6.70998C8.31998 6.31998 7.68998 6.31998 7.29998 6.70998L2.70998 11.3C2.31998 11.69 2.31998 12.32 2.70998 12.71L7.29998 17.3C7.68998 17.69 8.31998 17.69 8.70998 17.3C9.09998 16.91 9.09998 16.28 8.70998 15.89L5.82998 13H20C20.55 13 21 12.55 21 12V7.99998C21 7.44998 20.55 6.99998 20 6.99998C19.45 6.99998 19 7.44998 19 7.99998Z" fill="#107DF6"/>
          </svg>
        </span>
        <span class="name">Logout</span>
      </a>
    </li>
  </ul>
</template>

<script>

export default {
  name: "CsSidebarMobile",
  emits: ["clickRouteLink"],
  components: {
  },
  data: () => ({
    links: [
      {
        title: 'Dashboard',
        url: '/dashboard',
        icon:`
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="6" height="7" rx="1" stroke="#107df6"
                          stroke-width="2" stroke-linejoin="round"/>
                    <rect x="4" y="15" width="6" height="5" rx="1" stroke="#107df6"
                          stroke-width="2" stroke-linejoin="round"/>
                    <rect x="14" y="4" width="6" height="5" rx="1" stroke="#107df6"
                          stroke-width="2" stroke-linejoin="round"/>
                    <rect x="14" y="13" width="6" height="7" rx="1" stroke="#107df6"
                          stroke-width="2" stroke-linejoin="round"/>
                </svg>
            `
      },
      {
        title: 'Reports',
        url: '/reports',
        icon:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 21H6C5.44772 21 5 20.5523 5 20L5 4C5 3.44772 5.44772 3 6 3L13.5631 3C13.8416 3 14.1076 3.1162 14.2968 3.32059L18.7338 8.11246C18.9049 8.29731 19 8.53995 19 8.79187L19 20C19 20.5523 18.5523 21 18 21Z" stroke="#107DF6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 17H15" stroke="#107DF6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 14H15" stroke="#107DF6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19 9L14 9C13.4477 9 13 8.55228 13 8L13 3" stroke="#107DF6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>`
      },
      {
        title: 'Orders',
        url: '/orders',
        icon:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6 11L6 4C6 3.44772 6.44772 3 7 3L14.5631 3C14.8416 3 15.1076 3.1162 15.2968 3.3206L19.7338 8.11246C19.9049 8.29731 20 8.53995 20 8.79187V20C20 20.5523 19.5523 21 19 21H13"
                        stroke="#107df6" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                    <path d="M4 21H6.5L14 13.5L11.5 11L4 18.5V21Z" stroke="#107df6"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 14L11 16" stroke="#107df6" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20 9L15 9C14.4477 9 14 8.55228 14 8L14 3" stroke="#107df6"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`
      },
      {
        title: 'Account setup',
        url: '/account-setup',
        icon:`<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                        stroke="#107df6" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                    <path
                        d="M19.1671 7.94004L19.1013 7.82619C18.9199 7.51217 18.8273 7.15475 18.8334 6.79216L18.8634 5.01333C18.8695 4.64981 18.6778 4.3116 18.3628 4.13007L15.6261 2.55307C15.3119 2.372 14.9243 2.37524 14.6131 2.56155L13.094 3.47113C12.7836 3.65704 12.4285 3.75522 12.0666 3.75522H11.9349C11.5722 3.75522 11.2164 3.65661 10.9055 3.46994L9.3794 2.55377C9.06716 2.36632 8.6777 2.36344 8.36272 2.54625L5.63473 4.12954C5.32124 4.31149 5.13075 4.64883 5.13685 5.01125L5.16681 6.79216C5.17291 7.15475 5.08031 7.51217 4.89892 7.82619L4.83403 7.93852C4.65254 8.25271 4.389 8.5116 4.07164 8.68748L2.51675 9.54914C2.19805 9.72576 2.00064 10.0617 2.00147 10.4261L2.00863 13.5779C2.00945 13.9401 2.20601 14.2735 2.52248 14.4495L4.0699 15.3104C4.38835 15.4875 4.65235 15.7483 4.83337 16.0646L4.9029 16.186C5.08174 16.4985 5.17293 16.8534 5.16685 17.2133L5.13692 18.9865C5.13078 19.35 5.32245 19.6882 5.63749 19.8698L8.37412 21.4468C8.68835 21.6278 9.07598 21.6246 9.38713 21.4383L10.9062 20.5287C11.2167 20.3428 11.5718 20.2446 11.9337 20.2446H12.0654C12.428 20.2446 12.7838 20.3432 13.0948 20.5299L14.6208 21.4461C14.9331 21.6335 15.3226 21.6364 15.6375 21.4536L18.3655 19.8703C18.679 19.6884 18.8695 19.351 18.8634 18.9886L18.8334 17.2077C18.8273 16.8451 18.9199 16.4877 19.1013 16.1737L19.1662 16.0613C19.3477 15.7471 19.6112 15.4882 19.9286 15.3124L21.4835 14.4507C21.8022 14.2741 21.9996 13.9381 21.9988 13.5738L21.9916 10.4219C21.9908 10.0598 21.7942 9.72638 21.4778 9.55032L19.9266 8.68741C19.6105 8.51156 19.348 8.25327 19.1671 7.94004Z"
                        stroke="#107df6" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>`
      },
      {
        title: 'Cart',
        url: '/cart',
        icon:`<svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 12.5C4.175 12.5 3.5075 13.175 3.5075 14C3.5075 14.825 4.175 15.5 5 15.5C5.825 15.5 6.5 14.825 6.5 14C6.5 13.175 5.825 12.5 5 12.5ZM0.5 1.25C0.5 1.6625 0.8375 2 1.25 2H2L4.7 7.6925L3.6875 9.5225C3.14 10.5275 3.86 11.75 5 11.75H13.25C13.6625 11.75 14 11.4125 14 11C14 10.5875 13.6625 10.25 13.25 10.25H5L5.825 8.75H11.4125C11.975 8.75 12.47 8.4425 12.725 7.9775L15.41 3.11C15.6875 2.615 15.3275 2 14.7575 2H3.6575L3.155 0.9275C3.035 0.665 2.765 0.5 2.48 0.5H1.25C0.8375 0.5 0.5 0.8375 0.5 1.25ZM12.5 12.5C11.675 12.5 11.0075 13.175 11.0075 14C11.0075 14.825 11.675 15.5 12.5 15.5C13.325 15.5 14 14.825 14 14C14 13.175 13.325 12.5 12.5 12.5Z" fill="#107DF6"/>
              </svg>`
      },
    ]
  }),
  methods: {
    async logout() {
      await this.$store.dispatch('apiLogoutUser')
      this.$router.push('/sing-in')
    }
  }

}
</script>

<style scoped>

</style>
