import apiAxios from "@/api-axios";

export default {
  state: {
    userDataTypes: [],
    userDataLastScores: {},
    userDataLastNegativeRecords: {},
    userDataLastHardInquiries: {},
    userDataLastConsumerInquiries: {},
  },
  mutations: {
    setUserDataTypes(state, userDataTypes) {
      state.userDataTypes = userDataTypes;
    },
    setUserDataLastScores(state, userDataLast) {
      state.userDataLastScores = userDataLast;
    },
    setUserDataLastNegativeRecords(state, userDataLast) {
      state.userDataLastNegativeRecords = userDataLast;
    },
    setUserDataLastHardInquiries(state, userDataLast) {
      state.userDataLastHardInquiries = userDataLast;
    },
    setUserDataLastConsumerInquiries(state, userDataLast) {
      state.userDataLastConsumerInquiries = userDataLast;
    },
    clearUserDataLast(state) {
      state.userDataLastScores = {};
      state.userDataLastNegativeRecords = {};
      state.userDataLastHardInquiries = {};
      state.userDataLastConsumerInquiries = {};
    },
    clearUserDataTypes(state) {
      state.userDataTypes = [];
    }
  },
  getters: {
    userDataTypes: s => s.userDataTypes,
    userDataLastScores: s => s.userDataLastScores,
    userDataLastNegativeRecords: s => s.userDataLastNegativeRecords,
    userDataLastHardInquiries: s => s.userDataLastHardInquiries,
    userDataLastConsumerInquiries: s => s.userDataLastConsumerInquiries,
  },
  actions: {
    async apiGetUserDataTypes({commit, dispatch}, params) {
      try {
        const response = await apiAxios.get('/user_data/types/', params);
        commit('setUserDataTypes', response.data);
      } catch (e) {
        dispatch('catchError', e);
      }
    },
    async apiGetUserDataLast({commit, dispatch}, {commitName, params}) {
      try {
        const response = await apiAxios.get('/user_data/last/', params);
        await commit(commitName, response.data);
      } catch (e) {
        dispatch('catchError', e);
      }
    }
  }
}
