
export default {
  methods: {
    getStatusPreload(preloadType) {
      return this.$store.getters.preloader[preloadType]||false;
    },
    async onPreloader(preloadType){
      await this.$store.commit('onPreloader', preloadType);
    },
    async offPreloader(preloadType){
      await this.$store.commit('offPreloader', preloadType);
    },
    async preloadSwitcher(preloadType, callback, ...arg) {
      try {
        await this.onPreloader(preloadType);
        await callback(...arg);
      }finally {
        await this.offPreloader(preloadType);
      }
    }
  }
}
