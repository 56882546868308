import apiAxios from "@/api-axios";

export default {
  data() {
    return {
      colors: {
        'red': '#ED5656',
        'grey': '#BBBBBB',
        'orange': '#F98600',
      }
    };
  },
  computed: {
    monthNames() {
      return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    }
  },
  methods: {
    format(mask, number, replace='X') {
      let s = ''+number, r = '';
      for (let im=0, is = 0; im<mask.length && is<s.length; im++) {
        r += mask.charAt(im)===replace ? s.charAt(is++) : mask.charAt(im);
      }
      return r;
    },
    hideNumber(number) {
      if (!number) {
        return '';
      }
      let blocks = number.split('-');
      return blocks.map((block, idx)=>{
        if (++idx == blocks.length) {
          return block;
        }
        return block.split('').map(()=>'*').join('');
      }).join('-');
    },
    getColor(color) {
      if (this.colors[color] !== undefined) {
        return this.colors[color];
      }
      return color;
    },
    ucFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    ucFirstLC(str){
      return this.ucFirst(str.toLowerCase());
    },
    unique(arr) {
      return arr.filter((val, idx, self)=>self.indexOf(val)===idx);
    },
    lastDataToBureaus(arr) {
      const options = [];
      for (let values of Object.values(Object.values(arr))) {
        options.push(...Object.keys(values));
      }
      return this.unique(options);
    },
    currency(cost) {
      cost *= 1;
      return '$'+(cost.toFixed(2).toString());
    },
    orderTaskStatus(status){
      let classObject = {};
      classObject[status.toLowerCase()] = true;
      return classObject
    },
    orderNumber(order) {
      return order.number_prefix+order.number;
    },
    orderDisputeNumber(dispute) {
      return dispute.number_prefix+dispute.number;
    },
    orderDisputeName(dispute) {
      return dispute.name.join(' | ');
    },
    formatUserFullName(profile) {
      return [profile.first_name, profile.middle_name, profile.second_name].filter(str=>str.trim().length).join(' ');
    },
    fileToUrl(field) {
      if (!field) {
        return ''
      }
      const API_URL = process.env.VUE_APP_API_URL;
      const API_VER = process.env.VUE_APP_API_VERSION;
      return API_URL + API_VER + field;
    },
    imageToUrl(object, field) {
      if (object[field]?.filepath===undefined) {
        return this.defaultImage();
      }
      return this.fileToUrl(object[field]?.filepath);
    },
    async loadBlobJWT(url) {
      if (!url.length) {
        return this.defaultImage();
      }
      const urlHash = this.hashString(url);
      if (this.$store.getters.blobCache?.[urlHash]) {
        return Promise.resolve(this.$store.getters.blobCache?.[urlHash]);
      }
      console.info('Load with JWT:', url)
      return await apiAxios.get(url, {}, {responseType:"blob"})
        .then(this.getBase64Data)
        .then(blobData=>{
          this.$store.commit('setCacheData', {key:urlHash, data: blobData});
          return blobData;
        });
    },
    async getBase64Data(response) {
      const blob = await response.data;
      const reader = new FileReader();
      await new Promise((resolve, reject) =>{
        reader.onload = resolve;
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      })
      return reader.result;
    },
    defaultImage () {
      return require('@/assets/images/icons/empty-image.svg');
    },
    hashString(str) {
      // Create an array of 16 32-bit integers.
      let hash = new Uint32Array(16);

      // Loop through each character in the string.
      for (let i = 0; i < str.length; i++) {
        // Convert the character to its numeric value.
        let c = str.charCodeAt(i);

        // Update the hash array.
        for (let j = 0; j < 16; j++) {
          hash[j] = (hash[j] * 31 + c) & 0xFFFFFFFF;
        }
      }

      // Convert the hash array to a string.
      let hashString = '';
      for (let i = 0; i < 16; i++) {
        hashString += hash[i].toString(16);
      }

      // Return the hash string.
      return hashString;
    },
    scrollIntoView(el) {
      return this.$nextTick(()=> {
        let elementsHeader = document.getElementsByTagName('header');
        let offsetHeader = 120;
        if (elementsHeader && elementsHeader.length > 0) {
          offsetHeader = elementsHeader[0].offsetHeight + 40;
        }
        if (el) {
          el.scrollIntoView();
          window.scroll({
            top: window.pageYOffset - offsetHeader,
            behavior: 'smooth'
          })
        }
      });
    },
    groupPayments(payments) {
      if (payments && payments.length) {
        return payments.reduce((acc, item)=>{
          if (acc[item.year]) {
            acc[item.year].push(item);
          } else {
            acc[item.year] = [item];
          }
          return acc;
        }, {});
      }
      return {};
    },
    paymentValue(row, month){
      let col = row.find(col => col.month===month);
      if (col){
        return col;
      }
      return false;
    },
    paymentValues(row) {
      return this.monthNames.map(monthName=> this.paymentValue(row, monthName));
    },

    getStatusClass(status) {
      if (['SUCCESS'].indexOf(status)!==-1) {
        return 'complete';
      }
      if(['CREATED', 'PENDING', 'PROCESSING'].indexOf(status)!==-1) {
        return 'progress';
      }
      if (['ACTION'].indexOf(status)!==-1) {
        return 'require';
      }
      return 'progress';
    },

    getTextByStatus(status) {
      if (['SUCCESS'].indexOf(status)!==-1) {
        return 'complete';
      }
      if(['CREATED', 'PENDING', 'PROCESSING'].indexOf(status)!==-1) {
        return 'in progress';
      }
      if (['ACTION'].indexOf(status)!==-1) {
        return 'require';
      }
      return 'progress';
    },
  }
}
